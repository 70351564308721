table.table-sm th,
table.table-sm td {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.table-sm {
  font-size: 0.8em;
}

.form-control {
  width: 75%;
  display: inline;
  float: right;
}

.form-control-smn {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.825rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  width: 100% !important;
}

.table-sm .newrow .form-control {
  width: 100%;
}

@media screen and (max-width: 2600px) {
  .form-control {
    width: 70%;
    display: inline;
    float: right;
  }

  .field-radio-group {
    width: 70%;
    display: inline;
    float: right;
  }

  .sidebar {
    margin: 0px;
    transition: all 0.3s ease-in-out;
    width: 240px;
    top: 72px;
    position: absolute;
    left: 0px;
    z-index: 9;
  }

  .neworder {
    transition: all 0.3s ease-in-out;
    width: calc(100% - 240px);
    float: right;
    left: 0px;
    position: relative;
  }

  .sidebarButton {
    display: none;
  }

  .tdShortDesc {
    display: none;
  }

  .grid-card {
    text-align: center;
    width: 12rem;
    height: 22rem;
  }
}

@media screen and (max-width: 1598px) {
  .form-control {
    width: 70%;
    display: inline;
    float: right;
  }

  .field-radio-group {
    width: 70%;
    display: inline;
    float: right;
  }

  .sidebar {
    margin: 0px;
    transition: all 0.3s ease-in-out;
    width: 240px;
    top: 72px;
    position: absolute;
    left: 0px;
    z-index: 9;
  }

  .neworder {
    transition: all 0.3s ease-in-out;
    width: calc(100% - 240px);
    float: right;
    left: 0px;
    position: relative;
  }

  .sidebarButton {
    display: none;
  }

  .tdShortDesc {
    display: none;
  }

  .grid-card {
    text-align: center;
    width: 12rem;
    height: 22rem;
  }
}

@media screen and (max-width: 900px) {
  .form-control {
    width: 70%;
    display: inline;
    float: right;
  }

  .field-radio-group {
    width: 70%;
    display: inline;
    float: right;
  }

  .sidebar {
    margin: 0px;
    transition: all 0.3s ease-in-out;
    width: 240px;
    top: 72px;
    position: absolute;
    left: 0px;
    z-index: 9;
  }

  .neworder {
    transition: all 0.3s ease-in-out;
    width: calc(100% - 240px);
    float: right;
    left: 0px;
    position: relative;
  }

  .sidebarButton {
    display: none;
  }

  .tdShortDesc {
    display: none;
  }

  .grid-card {
    text-align: center;
    width: 12rem;
    height: 22rem;
  }
}

@media screen and (max-width: 600px) {
  .container {
    padding: 3px;
  }

  body {
    padding: 0;
    margin: 0;
  }

  .form-control {
    width: 100%;
    display: block;
  }

  .field-radio-group {
    width: 100%;
    display: block;
  }

  .sidebar {
    position: relative;
    margin: 0px;
    transition: all 0.3s ease-in-out;
    top: 0px;
    width: 240px;
    left: -15px;
    z-index: 9;
  }

  .sidebarButton {
    margin-right: 20px;
    display: none;
  }

  .neworder {
    width: 100%;
    margin-top: 30px;
    float: left;
    max-width: fit-content;
  }

  .tdLongDesc {
    display: none;
  }

  .tdShortDesc {
    display: block;
  }

  .grid-card {
    text-align: center;
    width: 14rem;
    height: 22rem;
  }
}

.field-radio-group {
  width: 70%;
  display: inline;
  float: right;
}

.rjsf .form-group {
  margin-bottom: 0.5rem;
}

.rjsf .form-group .form-control {
  font-size: 0.8rem;
}

.field-radio-group .radio {
  display: inline;
  padding-right: 20px;
}

.btn-xs {
  padding: 0.25rem 0.35rem;
  font-size: 0.435rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.total-records {
  font-size: 1.2em;
  font-weight: bold;
}

.btn-calculator {
  color: white;
  background-color: coral;
  border-color: coral;
}

.badge-sheet {
  font-size: 100%;
  background-color: darkgray;
  border-color: darkgray;
}

.rjsf .form-group {
  clear: both;
}

.rjsf .form-group .checkboxes {
  padding-right: 220px;
  float: right;
}

.pagination {
  justify-content: center;
}

.pagination ul {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.pagination ul li {
  display: inline-block !important;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
}

.pagination ul li a {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: 0;
  line-height: 1.25;
  color: #fff;
  background-color: #18bc9c;
  border: 0 solid transparent;
}

.pagination ul li.active a {
  color: white;
}

.pagination ul li.selected,
.pagination ul li.selected a {
  color: #fff;
  background-color: #0f7864;
  border-color: transparent;
}

.pagination ul li.disabled,
.pagination ul li.disabled a {
  color: #fff;
  background-color: #aaa;
  border-color: transparent;
}

.page-selection {
  width: 48px;
  height: 30px;
  color: #337ab7;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

tr.groupby {
  background-color: #87cefa !important;
}

tr.subgroupby {
  background-color: #add8e6 !important;
}

tr.total {
  background-color: rgb(253, 229, 148) !important;
}

tr.grandtotal {
  background-color: #c3fd94 !important;
}

div.ppptable-row {
  display: flex;
  flex-wrap: wrap;
}

div.ppptable-col {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  width: 100%;
  max-width: 100%;
  position: relative;
  padding-right: 5px;
}

span.ppp-link {
  cursor: pointer;
}

@media (min-width: 1599px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1540px;
  }
  .neworder {
    transition: all 0.3s ease-in-out;
    width: calc(100% - 240px);
    float: right;
    left: 0px;
    position: relative;
  }

  .sidebarButton {
    display: none;
  }

  .tdShortDesc {
    display: none;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1380px;
  }

  .neworder {
    transition: all 0.3s ease-in-out;
    width: calc(100% - 240px);
    float: right;
    left: 0px;
    position: relative;
  }

  .sidebarButton {
    display: none;
  }

  .tdShortDesc {
    display: none;
  }
}

@media (min-width: 1890px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1870px;
  }

  .neworder {
    transition: all 0.3s ease-in-out;
    width: calc(100% - 240px);
    float: right;
    left: 0px;
    position: relative;
  }

  .sidebarButton {
    display: none;
  }

  .tdShortDesc {
    display: none;
  }
}

@media (min-width: 900px) {
  .modal-dialog {
    max-width: 700px;
    margin: 1.75rem auto;
  }
}

.bg-afds2 {
  background-color: #be0000;
}

.bg-mongodb {
  background-color: #be5900;
}

.bg-afds3 {
  background-color: #008800;
}

.bg-afds4 {
  background-color: #0000be;
}

.bg-afds6 {
  background-color: #888;
}

.bg-afds5 {
  background-color: #aaa;
}

.bg-image-gallery {
  background-color: #888;
  padding: 3px;
}

.header {
  background-color: #87cefa;
  font-weight: bold;
}

.newrow {
  background-color: rgb(158, 247, 56);
}

.table-sm tbody tr td input.form-control {
  width: 100%;
  font-size: 0.95em;
}

.table-sm tbody tr td select.form-control {
  width: 100%;
  font-size: 0.95em;
}

.table-sm tbody tr td textarea.form-control {
  width: 100%;
  font-size: 0.95em;
}

.table-sm thead tr td .form-control {
  width: 100%;
  font-size: 0.95em;
}

.table-sm tfoot tr td input.form-control {
  width: 100%;
  font-size: 0.95em;
}

.table-sm tfoot tr td select.form-control {
  width: 100%;
  font-size: 0.95em;
}

.table-sm tfoot {
  background-color: honeydew;
}

.form-row {
  font-size: 0.8em;
  width: 60%;
}

.form-row input.form-control,
.form-row select.form-control {
  width: 100%;
}

.select-orderform {
  width: 100% !important;
  min-height: 120px;
}

.sidebarButton {
  position: absolute;
  left: 0;
  top: 72px;
  width: 20px;
  height: 30px;
  border-width: 0px;
  background: lightblue;
}

.sidebarButtonLeft {
  position: absolute;
  left: 220px;
  width: 20px;
  height: 30px;
  border-width: 0px;
  background: lightblue;
}
.cart-icon-container {
  position: relative;
  display: inline-block;
}

.cart-icon-container .badge {
  position: absolute;
  top: 50%;
  right: -16px;
  transform: translate(0, -50%);
}

.navbar .dropdown-menu a {
  padding: 5px 10px 5px 10px !important;
}

.form-file-upload {
  width: 48rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

.form-file-upload .drag-over {
  background-color: #203364 !important;
  color: #fff;
}

#input-file-upload0,
#input-file-upload1 {
  display: none;
}

.MuiTreeItem-label:hover {
  text-overflow: unset;
  overflow: visible;
  width: 100%;
  color: royalblue;
  font-weight: bold;
}

#label-file-upload {
  height: 50%;
  display: block;
  padding-top: 5%;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  border-color: darkolivegreen;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

.react-pdf__Page {
  border: dashed;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}
.bg-primary {
  background-color: #299aba !important;
  color: white !important;
}

.favorites-button {
  position: absolute;
  left: -20px;
  top: -30px;
}
